import { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

import config from "../config";
// import chimeAudioFile from "../assets/audio/chime.mp3";

const { paymentSocketUrl } = config().secrets;

export const socket = io.connect(paymentSocketUrl, {
	transports: ["websocket", "polling", "flashsocket"],
});

export const PaymentSocketContext = createContext();

const PaymentSocketProvider = (props) => {
	const [clientId, setClientId] = useState("");
	const [paymentStatuses, setPaymentStatuses] = useState([]);

	useEffect(() => {
		socket.on("connect", () => {
			console.log(`Connection opened on : ${socket.id}`);
		});

		socket.on("connected", (message) => {
			setClientId(socket.id);
			console.log(message);
			const response = JSON.parse(message);

			if (response.method === "connected" && response.clientId === clientId) {
				console.log(`Client id Set successfully ${clientId}`);
			}
		});

		socket.on("payment_status", (message) => {
			const response = JSON.parse(message);
			// console.log({ response });
			setPaymentStatuses(() => response.data);
		});

		return () => {
			socket.off("payment_status");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket]);

	return (
		<PaymentSocketContext.Provider
			value={{
				socket,
				clientId,
				paymentStatuses,
			}}
		>
			{props.children}
		</PaymentSocketContext.Provider>
	);
};

export default PaymentSocketProvider;
