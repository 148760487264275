import { ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider, QueryClient } from "react-query";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { store } from "./store/index";
import { loginSuccess, setRegion } from "./slices/authSlice";
import {
	loginSuccess as userLoginSuccess,
	setRegion as setUserRegion,
} from "./slices/userAuthSlice";
import { REGION_KEY, ROLEKEY, USERKEY } from "./utils/constants";
import auth from "./utils/helpers/auth";
import customTheme from "./config/themeConfig";
import BaseRoute from "./pages/routes/BaseRoutes";
import { ModalProvider } from "./hooks/ModalContext";
import DownloadAppModal from "./components/Home/DownloadAppModal";
import SupportSocketProvider from "./context/chatSocket";
import "react-toastify/dist/ReactToastify.css";
import PaymentSocketProvider from "./context/paymentSocket";

if (auth.authenticate()) {
	const accessCode = auth.getAccessCode();
	const region = localStorage.getItem(REGION_KEY);
	const parsedRegion = JSON.parse(region);

	store.dispatch(
		loginSuccess({
			accessCode,
		})
	);
	if (region) {
		store.dispatch(setRegion(parsedRegion));
	}
}

if (auth.userAuthenticate()) {
	const user = localStorage.getItem(USERKEY);
	const parsedUser = JSON.parse(user);
	const region = localStorage.getItem(REGION_KEY);
	const parsedRegion = JSON.parse(region);
	store.dispatch(
		userLoginSuccess({
			...parsedUser,
			role: localStorage.getItem(ROLEKEY),
		})
	);
	if (region) {
		store.dispatch(setUserRegion(parsedRegion));
	}
}

function App() {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				retry: false,
				cacheTime: 0,
			},
		},
	});
	return (
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<SupportSocketProvider>
					<PaymentSocketProvider>
						<ThemeProvider theme={customTheme}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<ModalProvider>
									<CssBaseline />
									<ToastContainer />
									<BaseRoute />

									<DownloadAppModal />
								</ModalProvider>
							</LocalizationProvider>
						</ThemeProvider>
					</PaymentSocketProvider>
				</SupportSocketProvider>
			</QueryClientProvider>
		</Provider>
	);
}

export default App;
