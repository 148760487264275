const env = process.env.NODE_ENV || "development";

let cache;

const config = () => {
	if (!cache) {
		cache = Object.freeze({
			env,
			secrets: {
				apiHost:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://core-staging.smartdeals.com.ng"
						: "https://core.smartdeals.com.ng",
				encryptionPassword:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? process.env.REACT_APP_ENCRYPTION_PASSWORD_DEV
						: process.env.REACT_APP_ENCRYPTION_PASSWORD,
				encryptionSecretKey:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? process.env.REACT_APP_ENCRYPTION_SECRETKEY_DEV
						: process.env.REACT_APP_ENCRYPTION_SECRETKEY,
				docsClient:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://docs-staging.smartdeals.com.ng"
						: "https://docs.smartdeals.com.ng",
				googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
				hashKey: process.env.REACT_APP_HASH_KEY,
				payPassword: process.env.REACT_APP_PAY_PASSWORD,
				paySecret: process.env.REACT_APP_PAY_SECRET,
				chatSocketUrl:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://socket-staging.smartdeals.com.ng:9490"
						: "https://socket.smartdeals.com.ng:9490",
				paymentSocketUrl:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "wss://support-staging.smartdeals.com.ng:8290"
						: "wss://support.smartdeals.com.ng:8290",
			},
		});
	}
	return cache;
};

export default config;
